import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  DocumentSnapshot,
  getCountFromServer,
  onSnapshot,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { Site, CreateSiteData, UserChanges } from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  createSite,
  registerSiteChanges,
  confirmSiteRegistration,
  rejectSiteRegistration,
  confirmSiteChanges,
  rejectSiteChanges,
  confirmSiteRejection,
  confirmSiteChangesRejection,
  requestSiteDeletion,
  cancelSiteDeletion,
} from '../db/sites';

import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { getTotalVolume, unflatten } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';
import { useUser } from './use-user';

type Count = {
  totalSites: number;
  totalActive: number;
  totalInactive: number;
  foundationLevel: number;
  castingLevel: number;
  plasteringLevel: number;
  blockSettingLevel: number;
  tilingStage: number;
};

export const useSites = (
  selectedCategory?: string,
  activityLevel?: string,
  siteOwnerId?: string,
  pageSize = 20
) => {
  const { id } = useAuth();
  const [count, setCount] = useState<Count>({
    totalSites: 0,
    totalActive: 0,
    totalInactive: 0,
    foundationLevel: 0,
    castingLevel: 0,
    plasteringLevel: 0,
    blockSettingLevel: 0,
    tilingStage: 0,
  });
  const [sitesQuery, setSitesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    sitesQuery,
    pageSize
  );

  useEffect(() => {
    let siteWhere = [where('userIds', 'array-contains', id)];
    siteWhere.push(where('active', '==', true));
    if (selectedCategory) {
      switch (selectedCategory) {
        case 'actionRequired': {
          siteWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
          break;
        }
        case 'numberWrong': {
          siteWhere.push(where('isSiteActive', '==', false));
          break;
        }
        default: {
          break;
        }
      }
    }

    if (activityLevel && activityLevel !== 'all') {
      if (activityLevel === 'isActive' || activityLevel === 'isInactive') {
        siteWhere.push(
          where('isSiteActive', '==', activityLevel === 'isActive')
        );
      } else {
        siteWhere.push(where('activityLevel', '==', activityLevel));
      }
    }

    if (id && siteOwnerId) {
      siteWhere.push(where('siteOwner.id', '==', siteOwnerId));

      setSitesQuery(
        query(
          collection(firestore, 'sites'),

          ...siteWhere
        )
      );
    } else if (id) {
      setSitesQuery(query(collection(firestore, 'sites'), ...siteWhere));
    } else if (sitesQuery) {
      setSitesQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedCategory, siteOwnerId, activityLevel]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  useEffect(() => {
    if (id) {
      const coll = collection(firestore, 'sites');
      const totalSites = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id)
      );
      const totalActive = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('isSiteActive', '==', true)
      );
      const totalInactive = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('isSiteActive', '==', false)
      );
      const foundationLevel = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('activityLevel', '==', 'foundation')
      );
      const castingLevel = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('activityLevel', '==', 'casting')
      );
      const plasteringLevel = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('activityLevel', '==', 'plastering')
      );
      const blockSettingLevel = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('activityLevel', '==', 'block_setting')
      );
      const tilingStage = query(
        coll,
        where('active', '==', true),
        where('userIds', 'array-contains', id),
        where('activityLevel', '==', 'tiling_stage')
      );

      // Use promise.all to get all the counts at once
      Promise.all([
        getCountFromServer(totalSites),
        getCountFromServer(totalActive),
        getCountFromServer(totalInactive),
        getCountFromServer(foundationLevel),
        getCountFromServer(castingLevel),
        getCountFromServer(plasteringLevel),
        getCountFromServer(blockSettingLevel),
        getCountFromServer(tilingStage),
      ]).then((values) => {
        setCount({
          totalSites: values[0].data().count,
          totalActive: values[1].data().count,
          totalInactive: values[2].data().count,
          foundationLevel: values[3].data().count,
          castingLevel: values[4].data().count,
          plasteringLevel: values[5].data().count,
          blockSettingLevel: values[6].data().count,
          tilingStage: values[7].data().count,
        });
      });
    }
  }, [id]);

  return useMemo(
    () => ({
      count,
      error,
      hasMore,
      load,
      loading,
      reset,
      sites: values
        ? values.map((v) => prepareSite(docToJSON(v) as Site))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useSitesTypesense = ({
  category,
  direction = 'asc',
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [sitesQuery, setSitesQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(sitesQuery, pageSize, 'sites', typesenseKeyUserIds);

  useEffect(() => {
    let categoryFilter = 'active:=true';
    if (category) {
      switch (category) {
        case 'actionRequired': {
          categoryFilter += '&&status:=[changes, unconfirmed';
          break;
        }
        case 'numberWrong': {
          categoryFilter += '&&phoneCorrect:=false';
          break;
        }
        case 'noWhatsApp': {
          categoryFilter += '&&hasWhatsApp:=false';
          break;
        }
        case 'enabledWhatsApp': {
          categoryFilter += '&&hasWhatsApp:=true';
          break;
        }
      }
    }

    if (typesenseKeyUserIds && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'name,siteOwner.name,primaryTAE.name',
        filter_by: `${categoryFilter}`,
        sort_by: `createdAt:${direction}`,
      };

      setSitesQuery(search);
    } else if (sitesQuery) {
      setSitesQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, category, pageSize, direction, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      sites: values
        ? values.map((v) => {
            return prepareSite(
              unflatten({
                ...v.document,
                id: v.document.id,
                createdAt: v.document.createdAt * 1000,
              })
            ) as Site;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useSite = (id?: string) => {
  const checkAccess = useCheckAccess();
  const { id: userId } = useAuth();
  const { user } = useUser();
  const { error, loading, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'sites', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    }
    setValue(undefined);
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: CreateSiteData) => {
    try {
      setLoading(true);
      console.log(data);
      await createSite(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerSiteChanges(id, checkAccess(['sop']), changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteRegistration(id)
        : await rejectSiteRegistration(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: UserChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmSiteChanges(id, changes)
        : await rejectSiteChanges(id, changes, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['tae'])) return;
    try {
      setLoading(true);
      confirmSiteRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    console.log(!id, !checkAccess(['tae']));
    if (!id || !checkAccess(['tae'])) return;
    try {
      setLoading(true);
      confirmSiteChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const requestDeletion = async () => {
    if (!id || !checkAccess(['tae', 'sop', 'fsm'])) return;
    try {
      setLoading(true);
      requestSiteDeletion(id, {
        id: userId,
        name: user.name,
        phone: user.phone,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const cancelDeletion = async () => {
    if (!id || !checkAccess(['tae', 'sop', 'fsm'])) return;
    try {
      setLoading(true);
      cancelSiteDeletion(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      site: value ? prepareSite(docToJSON(value) as Site) : null,
      error,
      loading,
      create,
      update,
      confirmChanges,
      confirmRegistration,
      confirmRejection,
      confirmChangesRejection,
      requestDeletion,
      cancelDeletion,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};

const prepareSite = (site: Site) => {
  let pendingVolume = getTotalVolume(site.leadsVolumePending);
  let acceptedVolume = getTotalVolume(site.leadsVolumeAccepted);
  let fulfilledVolume = getTotalVolume(site.leadsVolumeFulfilled);
  return {
    pendingVolume,
    acceptedVolume,
    fulfilledVolume,
    ...site,
  };
};
