import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import Back from '../../../components/ui/Back';
import Content from '../../../components/layout/Content';
import { useProduct } from '../../../lib/hooks/use-products';
import { useUser } from '../../../lib/hooks/use-user';

type ProductParams = 'id';

const Product = () => {
  const { id } = useParams<ProductParams>();
  const { loading, product } = useProduct(id);
  const { user } = useUser();

  useEffect(() => {
    console.log('product', product, user);
  }, [product, user]);

  return (
    <>
      <Content>
        {loading || product ? (
          <Card className="grid grid-cols-10">
            <Back to="/products" className="col-span-3" />
            <div className="col-span-4 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
              {product && product.defaultName}
            </div>
            <div className="col-span-12 flex justify-center items-center text-center text-xl text-lh-head-black">
              {product && user
                ? product.description &&
                  typeof product.description === 'string' &&
                  user
                  ? product.description
                  : product.description[
                      'cluster' in user
                        ? user.cluster.region.id
                        : user.clusters[0].region.id
                    ]
                : ''}
            </div>
            <br />
            <div className="col-span-12 flex justify-center items-center text-center">
              <img
                src={
                  product && user
                    ? product.imageURL &&
                      typeof product.imageURL === 'string' &&
                      user
                      ? product.imageURL
                      : product.imageURL[
                          'cluster' in user
                            ? user.cluster.region.id
                            : user.clusters[0].region.id
                        ]
                    : ''
                }
                height={250}
                width={250}
              />
            </div>
          </Card>
        ) : (
          <Card>Product not found!</Card>
        )}
      </Content>
    </>
  );
};

export default Product;
