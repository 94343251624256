import { useEffect, useState } from 'react';
import Loader from '../../components/ui/Loader';
import SearchAndSort from '../../components/ui/SearchAndSort';
import { useAssociates } from '../../lib/hooks/use-associates';
import Alert from '../../components/ui/Alert';
import Content from '../../components/layout/Content';
import ButtonBottomRight from '../../components/ui/ButtonBottomRight';
import TopBar from '../../components/layout/TopBar';
import List from '../../components/ui/list/List';
import Outlet from '../../components/outlet/Outlet';
import useStore from '../../lib/hooks/use-store';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import { useAuth } from '../../lib/hooks/use-auth';
import New from '../../components/ui/New';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { useOutlets, useOutletsTypesense } from '../../lib/hooks/use-outlets';

const Retailers = () => {
  const checkAccess = useCheckAccess();
  const { typesenseKey } = useAuth();

  const [state, dispatch] = useStore();
  const [searchText, setSearchText] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  const { error, hasMore, load, loading, outlets, reset } = useOutlets({
    category: state.retailerStatus,
  });

  const {
    error: errorTypesense,
    hasMore: hasMoreTypesense,
    load: loadTypesense,
    loading: loadingTypesense,
    outlets: outletsTypesense,
    reset: resetTypesense,
  } = useOutletsTypesense({
    category: state.retailerStatus,
    searchText: searchText,
  });

  const [showAlert, setShowAlert] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (typesenseKey) {
      setSearchEnabled(true);
    }
  });

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else if (showAlert) {
      setShowAlert(false);
    }
    return () => {
      setShowAlert(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onCategoryChange = (event) => {
    dispatch('SET_RETAILER_STATUS', event.target.value);
  };

  return (
    <Content topBar>
      <Alert
        title="Error"
        message={error && error.message}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <Alert
        title="Error"
        message={errorTypesense}
        open={showAlert}
        setOpen={(open) => setShowAlert(open)}
      />
      <ButtonBottomRight
        onClick={() => {
          reset();
          resetTypesense();
        }}
        blocked={loading}
        arialabel="reload"
        icon={faSync}
      />
      <TopBar>
        <SearchAndSort
          onCategoryChange={onCategoryChange}
          selectedCategory={state.retailerStatus}
          categories={{
            all: 'All',
            actionRequired: 'Action required',
            noWhatsApp: 'No WhatsApp',
            enabledWhatsApp: 'WhatsApp enabled',
          }}
          displayCategory={true}
          displaySearch={true}
          searchPlaceHolder="Retailer name"
          className={'mt-4'}
          setSearchText={setSearchText}
          searchDisabled={!searchEnabled}
        />
      </TopBar>
      {(!searchText || (searchText && searchText.length < 3)) && (
        <>
          <Loader show={loading} />
          <List load={load} hasMore={hasMore} loading={loading}>
            {outlets &&
              outlets.map((outlet) => (
                <Outlet outlet={outlet} key={outlet.id} />
              ))}
          </List>
        </>
      )}

      {searchText && searchText.length >= 3 && (
        <>
          <Loader show={loadingTypesense} />
          <List
            load={loadTypesense}
            hasMore={hasMoreTypesense}
            loading={loadingTypesense}
          >
            {outletsTypesense &&
              outletsTypesense.map((outlet) => (
                <Outlet outlet={outlet} key={outlet.id} />
              ))}
          </List>
        </>
      )}
      {checkAccess(['sop', 'fsa', 'fsm', 'zsm', 'npd_sa']) && <New to="new" />}
    </Content>
  );
};

export default Retailers;
