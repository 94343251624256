import { useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/hooks/use-auth';
import Button from '../ui/Button';
import { useSignOut } from '../../lib/auth/use-signout';
import { useCheckAccess } from '../../lib/auth/use-checkAccess';
import configureDateStore from '../../lib/store/date';
import configureDateInventoryStore from '../../lib/store/inventoryDate';
import configureRetailerStatusStore from '../../lib/store/retailerStatus';
import configureRolesStore from '../../lib/store/roles';
import configureRouteFSAStore from '../../lib/store/routeFSA';
import configureDateRoutePlanningStore from '../../lib/store/routePlanningDate';
import configureSelectedClusterStore from '../../lib/store/selectedCluster';
import configureStatusStore from '../../lib/store/status';
import configureTargetStatusStore from '../../lib/store/statusTarget';
import configureVisitsDateStore from '../../lib/store/visitsDate';

type MenuProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Menu = ({ open, setOpen }: MenuProps) => {
  const { user } = useAuth();
  const checkAccess = useCheckAccess();
  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location, setOpen]);
  return (
    <nav
      className={`fixed flex flex-col items-start justify-start pt-16 top-0 left-0 h-full text-left shadow-2xl transition-transform transform bg-gray-50 z-30 overflow-y-scroll ${
        open ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      {checkAccess([
        'sop',
        'fsa',
        'distributor',
        'tae',
        'fsm',
        'zsm',
        'npd_sa',
      ]) && <StyledNavLink to="/home">Home</StyledNavLink>}
      {checkAccess(['sop', 'fsa', 'distributor', 'fsm', 'zsm', 'npd_sa']) && (
        <StyledNavLink to="/orders">Orders</StyledNavLink>
      )}
      {checkAccess(['distributor', 'sop', 'zsm']) && (
        <StyledNavLink to="/sales">Direct Sales</StyledNavLink>
      )}
      {checkAccess(['fsa', 'npd_sa']) && (
        <StyledNavLink to="/visits">Visits</StyledNavLink>
      )}
      {checkAccess(['fsa', 'sop', 'fsm', 'zsm', 'npd_sa']) && (
        <StyledNavLink to="/routes">Routes</StyledNavLink>
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && (
        <StyledNavLink to="/routes-planning">Routes planning</StyledNavLink>
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && (
        <StyledNavLink to="/targets">Targets</StyledNavLink>
      )}
      {checkAccess(['sop', 'fsa', 'distributor', 'fsm', 'zsm', 'npd_sa']) && (
        <StyledNavLink to="/retailers">Outlets</StyledNavLink>
      )}
      {checkAccess(['sop', 'tae', 'fsm']) && (
        <StyledNavLink to="/sites">Sites</StyledNavLink>
      )}
      {/*checkAccess(['sop', 'tae']) && (
        <StyledNavLink to="/siteVisits">Site Visits</StyledNavLink>
      )*/}
      {/*checkAccess(['sop']) && <StyledNavLink to="/users">Users</StyledNavLink>*/}
      {checkAccess(['sop', 'distributor', 'fsm', 'zsm']) && (
        <StyledNavLink to="/inventories">Inventories</StyledNavLink>
      )}
      {checkAccess(['sop', 'distributor', 'fsm', 'zsm']) && (
        <StyledNavLink to="/deliveries">Deliveries</StyledNavLink>
      )}
      {checkAccess(['sop', 'fsa', 'distributor', 'tae', 'fsm']) && (
        <StyledNavLink to="/leads">Leads</StyledNavLink>
      )}
      {checkAccess(['sop', 'fsa', 'distributor', 'tae', 'fsm']) && (
        <StyledNavLink to="/copilot">Sales Co-Pilot</StyledNavLink>
      )}
      {checkAccess([
        'sop',
        'fsa',
        'distributor',
        'tae',
        'fsm',
        'zsm',
        'npd_sa',
      ]) && <StyledNavLink to="/products">Products</StyledNavLink>}
      {checkAccess([
        'sop',
        'fsa',
        'distributor',
        'tae',
        'fsm',
        'zsm',
        'npd_sa',
      ]) && <StyledNavLink to="/profile">Profile</StyledNavLink>}
      {checkAccess([
        'sop',
        'fsa',
        'distributor',
        'tae',
        'fsm',
        'zsm',
        'npd_sa',
      ]) && <StyledNavLink to="/faq">FAQ</StyledNavLink>}
      {checkAccess([
        'sop',
        'fsa',
        'distributor',
        'tae',
        'fsm',
        'zsm',
        'npd_sa',
      ]) && <StyledNavLink to="/complaints">Complaints</StyledNavLink>}
      {user && <SignOut />}
    </nav>
  );
};

type NavLinkProps = {
  to: string;
  children?: JSX.Element | JSX.Element[] | string;
};

const StyledNavLink = (props: NavLinkProps) => {
  return (
    <NavLink
      className={`text-xl pt-2 pb-2 pl-8 pr-28 text-lh-dark-blue no-underline transition-colors ${({
        isActive,
      }) => (isActive ? 'font-bold' : undefined)}`}
      to={props.to}
    >
      {props.children}
    </NavLink>
  );
};

const SignOut = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const onClickHandler = async () => {
    configureStatusStore();
    configureRetailerStatusStore();
    configureTargetStatusStore();
    configureDateStore();
    configureDateInventoryStore();
    configureDateRoutePlanningStore();
    configureRolesStore();
    configureRouteFSAStore();
    configureVisitsDateStore();
    configureSelectedClusterStore();
    await signOut();
    navigate('/', { replace: true });
  };
  return (
    <Button className="mt-auto self-center mb-8" onClick={onClickHandler}>
      Sign Out
    </Button>
  );
};

export default Menu;
