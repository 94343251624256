import { FirebaseError } from 'firebase/app';
import {
  Query,
  query,
  where,
  orderBy,
  FirestoreError,
  Unsubscribe,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { useState, useEffect, useMemo } from 'react';
import {
  DeliveryType,
  DeliveryStatus,
  PageDirection,
  User,
  Associate,
  Site,
} from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  claimDelivery,
  confirmDelivery,
  reassignDelivery,
  rejectDelivery,
} from '../db/deliveries';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { unflatten } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';

type DeliveriesHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  deliveries: DeliveryType[];
};

type DeliveriesHookProps = {
  status?: DeliveryStatus;
  userId?: string;
  pageSize?: number;
  direction: PageDirection;
};

type DeliveryHook = {
  claim: (newDistributor: User) => Promise<void>;
  confirm: () => void;
  delivery: DeliveryType;
  error: FirebaseError;
  loading: boolean;
  reassign: (newDistributor: Associate, sop: User) => Promise<void>;
  reject: (comment: string) => void;
};

export const useDeliveries = ({
  status = 'open',
  pageSize = 10,
  direction = 'asc',
}: DeliveriesHookProps): DeliveriesHook => {
  const { id } = useAuth();
  const [deliveriesQuery, setDeliveriesQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    deliveriesQuery,
    pageSize
  );

  useEffect(() => {
    if (id) {
      let q = query(
        collection(firestore, 'deliveries'),
        where('userIds', 'array-contains', id),
        orderBy('deliveryDate', direction)
      );

      if (status) {
        q = query(q, where('status', 'in', status));
      }
      setDeliveriesQuery(q);
    }
  }, [id, status, direction]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      deliveries: values
        ? values.map((v) => docToJSON(v) as DeliveryType)
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useDeliveriesTypesense = ({
  status = 'open',
  searchText,
  pageSize = 10,
  direction = 'asc',
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [deliveriesQuery, setDeliveriesQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(
      deliveriesQuery,
      pageSize,
      'deliveries',
      typesenseKeyUserIds
    );

  useEffect(() => {
    let categoryFilter = '';
    if (status) {
      categoryFilter += `status:=['${status}]`;
    }

    if (typesenseKeyUserIds && id && searchText) {
      let search = {
        q: searchText,
        query_by: 'LOGONOrderNumber,distributor.name,productNameInternal',
        filter_by: `${categoryFilter}`,
        sort_by: `createdAt:${direction}`,
      };

      setDeliveriesQuery(search);
    } else if (deliveriesQuery) {
      setDeliveriesQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, pageSize, direction, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      deliveries: values
        ? values.map((v) => {
            return unflatten({
              ...v.document,
              id: v.document.id,
              createdAt: v.document.createdAt * 1000,
            }) as Site;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useDelivery = (id?: string): DeliveryHook => {
  const checkAccess = useCheckAccess();
  const { error, loading, setLoading, setError, setValue, value } =
    useLoadingValue<DeliveryType | null, FirestoreError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      const ref = doc(firestore, `deliveries/${id}`);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists) {
            setValue(docToJSON(doc) as DeliveryType);
          } else {
            setValue(null);
          }
          setLoading(false);
        },
        setError
      );
    } else {
      setValue(null);
    }
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const confirm = async () => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      await confirmDelivery(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const reject = async (comment: string) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      await rejectDelivery(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const claim = async (newDistributor: User) => {
    if (!id || !checkAccess(['distributor'])) return;
    try {
      setLoading(true);
      await claimDelivery(id, newDistributor);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const reassign = async (newDistributor: Associate, sop: User) => {
    if (!id || !checkAccess(['sop'])) return;
    try {
      setLoading(true);
      await reassignDelivery(id, newDistributor, sop);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return useMemo(
    () => ({
      claim,
      confirm,
      delivery: value,
      error,
      loading,
      reassign,
      reject,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};
