import dayjs from 'dayjs';
import { Tier2Fulfillment as Tier2FulfillmentType } from '../../../../global';

type Tier2FulfillmentProps = {
  tier2Fulfillment: Tier2FulfillmentType;
};

const Tier2Fulfillment = ({ tier2Fulfillment }: Tier2FulfillmentProps) => {
  return (
    <>
      <h2 className="col-span-full mt-4 mb-2">
        {`${tier2Fulfillment && tier2Fulfillment.outlet.name} (
          ${dayjs(tier2Fulfillment.fulfilledAt * 1000).format(
            'DD.MM.YYYY HH:mm'
          )}
        )`}
      </h2>
      {tier2Fulfillment &&
        Object.keys(tier2Fulfillment.fulfilledProducts).map((product, key) => {
          if (
            tier2Fulfillment.fulfilledProducts[product] &&
            tier2Fulfillment.fulfilledProducts[product] > 0
          ) {
            return (
              <>
                <h3 className="col-span-2 py-1 text-lh-text-black text capitalize">
                  {product}
                </h3>
                <p className="col-span-3 col-start-7 mb-4 text-right py-1 px-3 font-semibold text-lh-text-black">
                  {tier2Fulfillment.fulfilledProducts[product]}
                </p>
                <p className="col-span-1 py-1 text-gray-500 font-thin">bags</p>
              </>
            );
          }
          return '';
        })}
    </>
  );
};

export default Tier2Fulfillment;
