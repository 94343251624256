import dayjs from 'dayjs';
import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  FirestoreError,
  onSnapshot,
  orderBy,
  Query,
  query,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import {
  Order,
  OrderStatus,
  CreateOrderData,
  UpdateOrderData,
  ProductsType,
} from '../../../../global';
import {
  acceptOrder,
  cancelOrder,
  createOrder,
  fulfillOrder,
  partialFulfillOrder,
  rejectOrder,
  tier2FulfillOrder,
  updateOrder,
} from '../db/orders';
import { firestore, docToJSON } from '../utils/firebase';
import { unflatten } from '../utils/helpers';
import { usePagination } from '../utils/usePagination';
import {
  SearchQuery,
  usePaginationTypesense,
} from '../utils/usePaginationTypesense';
import { useAuth } from './use-auth';
import { useUser } from './use-user';

type OrdersHook = {
  error: FirebaseError;
  hasMore: boolean;
  load: () => void;
  loading: boolean;
  reset: () => void;
  orders: Order[];
};

type OrdersHookOptions = {
  status: string[];
  pageSize?: number;
  direction?: 'asc' | 'desc';
  fsaId?: string;
};

export const useOrders = ({
  status,
  pageSize = 10,
  direction = 'asc',
  fsaId,
}: OrdersHookOptions): OrdersHook => {
  const { id } = useAuth();
  const [ordersQuery, setOrdersQuery] = useState<Query>();
  const { error, hasMore, load, loading, reset, values } = usePagination(
    ordersQuery,
    pageSize
  );

  useEffect(() => {
    if (id && fsaId && status.includes('tier2sales')) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('fsa.id', '==', fsaId),
          where(
            'status',
            'in',
            status.filter((element) => element !== 'tier2sales')
          ),
          where('hasTier2Fulfillments', '==', true),
          orderBy('createdAt', direction)
        )
      );
    } else if (id && fsaId) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('fsa.id', '==', fsaId),
          where('status', 'in', status),
          orderBy('createdAt', direction)
        )
      );
    } else if (id && status.includes('tier2sales')) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where(
            'status',
            'in',
            status.filter((element) => element !== 'tier2sales')
          ),
          where('hasTier2Fulfillments', '==', true),
          orderBy('createdAt', direction)
        )
      );
    } else if (id) {
      setOrdersQuery(
        query(
          collection(firestore, 'orders'),
          where('userIds', 'array-contains', id),
          where('status', 'in', status),
          orderBy('createdAt', direction)
        )
      );
    } else if (ordersQuery) {
      setOrdersQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, direction, fsaId]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      orders: values
        ? values.map((v) => prepareOrder(docToJSON(v) as Order))
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

export const useOrdersTypesense = ({
  status,
  direction = 'asc',
  searchText,
  pageSize = 10,
}) => {
  const { id, typesenseKeyUserIds } = useAuth();
  const [ordersQuery, setOrdersQuery] = useState<SearchQuery>();
  const { error, hasMore, load, loading, reset, values } =
    usePaginationTypesense(
      ordersQuery,
      pageSize,
      'orders-new',
      typesenseKeyUserIds
    );

  useEffect(() => {
    let categoryFilter = '';

    if (status.includes('tier2sales')) {
      categoryFilter += `hasTier2Fulfillments:=true && status:['${status
        .filter((element) => element !== 'tier2sales')
        .join(',')}']`;
    } else if (status) {
      categoryFilter += `status:["${status.join('","')}"]`;
    }

    if (typesenseKeyUserIds && id && searchText && direction) {
      let search: SearchQuery = {
        q: searchText,
        query_by: 'outlet.name,retailer.name,fsa.name',
        filter_by: `${categoryFilter}`,
        sort_by: `createdAt:${direction}`,
      };
      setOrdersQuery(search);
    } else if (ordersQuery) {
      setOrdersQuery(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status, pageSize, direction, searchText, typesenseKeyUserIds]);

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      error,
      hasMore,
      load,
      loading,
      reset,
      orders: values
        ? values.map((v) => {
            return prepareOrder(
              unflatten({
                ...v.document,
                id: v.document.id,
                createdAt: v.document.createdAt * 1000,
              })
            ) as Order;
          })
        : values,
    }),
    [error, hasMore, load, loading, reset, values]
  );
};

interface UseOrderHook {
  accept: (comment: string) => Promise<void>;
  cancel: (comment: string) => Promise<void>;
  create: (data: CreateOrderData) => Promise<void>;
  error: FirestoreError;
  fulfill: (
    productsDelivered: ProductsType,
    deliveredToConstructionSite: boolean
  ) => Promise<void>;
  loading: boolean;
  order: Order;
  partialFulfill: (
    productsDelivered: ProductsType,
    deliveredToConstructionSite: boolean
  ) => Promise<void>;
  reject: (comment: string) => Promise<void>;
  tier2Fulfill: (
    productsDelivered: ProductsType,
    tier2FulfillmentId: string
  ) => Promise<void>;
  update: (data: UpdateOrderData) => Promise<void>;
}

export const useOrder = (id?: string): UseOrderHook => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState<Order | undefined>();
  const [error, setError] = useState<FirestoreError>();
  const { user } = useUser();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      setLoading(true);
      const ref = doc(firestore, 'orders', id);
      unsubscribe = onSnapshot(
        ref,
        (doc) => {
          if (doc.exists()) {
            setOrder(docToJSON(doc) as Order);
          } else {
            setOrder(undefined);
          }
          setLoading(false);
        },
        (error: FirestoreError) => {
          setLoading(false);
          setError(error);
        }
      );
    }
    return unsubscribe;
  }, [id]);

  const create = async (data: CreateOrderData) => {
    try {
      setLoading(true);
      await createOrder(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const update = async (data: UpdateOrderData) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await updateOrder(id, data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const accept = async (comment: string) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await acceptOrder(id, comment, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const cancel = async (comment: string) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await cancelOrder(id, comment, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const reject = async (comment: string) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await rejectOrder(id, comment, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const fulfill = async (
    productsDelivered: ProductsType,
    deliveredToConstructionSite: boolean
  ) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await fulfillOrder(
        id,
        productsDelivered,
        deliveredToConstructionSite,
        user
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const partialFulfill = async (
    productsDelivered: ProductsType,
    deliveredToConstructionSite: boolean
  ) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await partialFulfillOrder(
        id,
        productsDelivered,
        deliveredToConstructionSite,
        user
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const tier2Fulfill = async (productsDelivered: ProductsType) => {
    if (!id) throw new Error('No order selected');
    try {
      setLoading(true);
      await tier2FulfillOrder(id, productsDelivered, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return {
    accept,
    cancel,
    create,
    error,
    fulfill,
    loading,
    order,
    partialFulfill,
    reject,
    tier2Fulfill,
    update,
  };
};

const prepareOrder = (order: Order): Order => {
  let totalOrdered = 0;
  Object.keys(order.products).forEach(
    (key) =>
      (totalOrdered += !isNaN(+order.products[key]) ? +order.products[key] : 0)
  );
  let totalDelivered = 0;
  if (order.productsDelivered)
    Object.keys(order.productsDelivered).forEach(
      (key) =>
        (totalDelivered += !isNaN(+order.productsDelivered[key])
          ? +order.productsDelivered[key]
          : 0)
    );
  let color = '';
  if (order.status === 'pending' || order.status === 'accepted') {
    const timeDiff = dayjs().diff(order.createdAt, 'hours');
    if (order.status === 'pending' && timeDiff > 12) {
      color = 'red';
    } else if (order.status === 'accepted' && timeDiff > 48) {
      color = 'red';
    }
  }
  const retailerName = order.retailer.name;
  return {
    ...order,
    totalOrdered,
    totalDelivered,
    color,
    retailerName,
  };
};
