import { faCalendarDay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Content from '../../../components/layout/Content';
import TopBar from '../../../components/layout/TopBar';
import SelectOutlet from '../../../components/routes/SelectOutlet';
import Alert from '../../../components/ui/Alert';
import Back from '../../../components/ui/Back';
import Button from '../../../components/ui/Button';
import Loader from '../../../components/ui/Loader';
import SortableList from '../../../components/ui/SortableList';
import { useCheckAccess } from '../../../lib/auth/use-checkAccess';
import { useRoute } from '../../../lib/hooks/use-routes';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ChangeRoute from '../../../components/routes/ChangeRoute';
import { Confirm } from '../../../components/forms/Confirm';
import { useEffect } from 'react';
import ButtonBottomRight from '../../../components/ui/ButtonBottomRight';

type RouteParams = 'id';

const RoutePage = () => {
  const { id } = useParams<RouteParams>();
  const {
    route,
    error,
    loading,
    outlets,
    update,
    addOutlet,
    removeOutlet,
    loadOutlets,
    updateWeight,
    deleteRoute,
  } = useRoute(id);
  const [showAlert, setShowAlert] = useState(false);
  const checkAccess = useCheckAccess();
  const [showSelectOutlet, setShowSelectOutlet] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (route) {
      if (route.lastEditStatus && !route.changeConfirmed) {
        setShowConfirmation(true);
      }
    }
  }, [route]);

  const add = async (data) => {
    let i = 1;
    for (let outlet of data) {
      await addOutlet({
        id: outlet.outletId,
        name: outlet.name,
        weight: route.outlets ? route.outlets + i : 1,
        createdAt: Timestamp.now(),
        retailerId: outlet.retailerId,
      });
      i++;
    }

    await update({ outlets: outlets.length + data.length });
    loadOutlets();
  };

  const changeRouteName = async (data) => {
    setShowChangeName(false);

    if (data.fsa) {
      await update({
        name: data.routeName,
        fsa: data.fsa,
        defaultDay: data.defaultDay,
        defaultDayNumber: data.defaultDayNumber,
      });
    } else {
      await update({
        name: data.routeName,
        defaultDay: data.defaultDay,
        defaultDayNumber: data.defaultDayNumber,
      });
    }
  };

  const removeOutletFromRoute = async (outletId) => {
    await update({ outlets: outlets.length - 1 });

    await removeOutlet(outletId);

    loadOutlets();
  };

  const moveHigher = async (outletId) => {
    let outletsArray = outlets;

    let index = outletsArray.findIndex((outlet) => {
      return outlet.id === outletId;
    });
    let oldWeight = outletsArray[index].weight;
    let otherOutlet = outletsArray[index - 1];

    await updateWeight(outletId, oldWeight - 1);
    await updateWeight(otherOutlet.id, oldWeight);

    loadOutlets();
  };

  const moveLower = async (outletId) => {
    let outletsArray = outlets;

    let index = outletsArray.findIndex((outlet) => {
      return outlet.id === outletId;
    });

    let oldWeight = outletsArray[index].weight;
    let otherOutlet = outletsArray[index + 1];

    await updateWeight(outletId, oldWeight + 1);
    await updateWeight(otherOutlet.id, oldWeight);

    loadOutlets();
  };

  const handleOnConfirm = async () => {
    await update({ changeConfirmed: true });
    await loadOutlets();
    setShowConfirmation(false);
  };

  const handleDeleteRoute = async () => {
    await deleteRoute();
    navigate('/routes');
  };

  const handleOnCancelDeleteRoute = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <Content topBar>
      <Loader show={loading} />
      {checkAccess(['sop', 'fsm', 'zsm']) && (
        <ButtonBottomRight
          onClick={() => setShowDeleteConfirmation(true)}
          color="red"
          arialabel="delete"
          blocked={false}
          icon={faTrash}
        />
      )}
      {checkAccess(['fsa', 'npd_sa']) && route && !route.changeConfirmed && (
        <Confirm
          title={`Your changes on this route have been ${route.lastEditStatus}.`}
          open={showConfirmation}
          onConfirm={handleOnConfirm}
          onCancel={() => {}}
          setOpen={(open) => {
            setShowConfirmation(open);
          }}
          oneButtonText="Confirm"
        />
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && (
        <SelectOutlet
          open={showSelectOutlet}
          onAddOutlet={add}
          setOpen={(open) => setShowSelectOutlet(open)}
          confirmText="Are you sure you want to add this outlet to this route?"
          buttonText="Add outlet to route"
          routeOutlets={outlets}
        />
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && (
        <Confirm
          title="Are you sure you want to delete this route?"
          open={showDeleteConfirmation}
          onConfirm={handleDeleteRoute}
          onCancel={handleOnCancelDeleteRoute}
          setOpen={(open) => {
            setShowDeleteConfirmation(open);
          }}
        />
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && route && (
        <ChangeRoute
          open={showChangeName}
          onChangeRouteName={changeRouteName}
          setOpen={(open) => setShowChangeName(open)}
          confirmText="Are you sure you want to update this route?"
          buttonText="Save route changes"
          routeName={route.name}
          fsaId={route.fsa.id}
          defaultDay={route.defaultDay}
        />
      )}
      {error && (
        <Alert
          title="Error"
          message={error.message}
          open={showAlert}
          setOpen={(open) => setShowAlert(open)}
        />
      )}
      <TopBar>
        <div className="w-full grid col-span-11 grid-cols-11 justify-center mt-4">
          <Back to="/routes" className="col-span-3" />
          <div className="col-span-5 flex justify-center items-center text-center text-xl font-bold text-lh-head-black">
            <p className="cursor-pointer font-bold">
              {route ? route.name : ''}
            </p>
          </div>
          <div className={`flex my-3 mr-2 col-span-3 flex-row-reverse`}>
            {checkAccess(['fsa', 'npd_sa']) && (
              <Link to={'edit'} className="pl-4 pr-3 py-2 ">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-xl text-lh-dark-blue"
                />
              </Link>
            )}
            {checkAccess(['sop', 'fsm', 'zsm']) && route && !route.edited && (
              <button
                onClick={() => setShowChangeName(true)}
                className="pl-4 pr-3 py-2 "
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-xl text-lh-dark-blue"
                />
              </button>
            )}
            {checkAccess(['sop', 'fsm', 'zsm']) && route && route.edited && (
              <Link to={'edit'}>
                <Button
                  onClick={() => navigate(`/edit`)}
                  className="pl-4 pr-3 py-2 "
                  color="red"
                >
                  Review changes
                </Button>
              </Link>
            )}
          </div>
          <h2 className="col-span-5 text-center font-bold text-lg text-lh-head-black">
            <FontAwesomeIcon
              icon={faUserCircle}
              className="mr-2 text-lh-head-black"
            />
            {route ? route.fsa.name : ''}
          </h2>
          <p className="col-span-5 col-start-7 font-bold text-center text-lg text-lh-text-black">
            <FontAwesomeIcon
              icon={faCalendarDay}
              className="mr-2 text-lh-head-black"
            />
            {route && route.defaultDay ? route.defaultDay : 'No default day'}
          </p>
        </div>
      </TopBar>
      {route && outlets && outlets.length > 0 ? (
        <SortableList
          items={outlets}
          type="routes"
          loading={loading}
          removeOutletFunction={removeOutletFromRoute}
          moveHigherFunction={moveHigher}
          moveLowerFunction={moveLower}
          routeEdited={route.edited ? true : false}
        />
      ) : (
        <p className="w-60 justify-between mx-2 mb-2 mt-10 h-18 list-none pb-4 px-2 pt-2 bg-white text-center">
          No outlets yet.
        </p>
      )}
      {checkAccess(['sop', 'fsm', 'zsm']) && route && !route.edited && (
        <Button
          onClick={() => setShowSelectOutlet(true)}
          className={
            'rounded-full h-20 w-20 flex items-center justify-center bg-green-500 fixed text-5xl bottom-5 left-1/2 -ml-10'
          }
          color="green"
        >
          +
        </Button>
      )}
    </Content>
  );
};

export default RoutePage;
