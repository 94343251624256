import { FirebaseError } from 'firebase/app';
import {
  collection,
  doc,
  DocumentSnapshot,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { CreateRetailerData, User, UserChanges } from '../../../../global';
import { useCheckAccess } from '../auth/use-checkAccess';
import {
  confirmRetailerChanges,
  confirmRetailerChangesRejection,
  confirmRetailerRegistration,
  confirmRetailerRejection,
  createRetailer,
  registerRetailerChanges,
  rejectRetailerChanges,
  rejectRetailerRegistration,
} from '../db/retailers';
import { useLoadingValue } from '../utils';
import { docToJSON, firestore } from '../utils/firebase';
import { useAuth } from './use-auth';

export const useRetailers = (selectedCategory?: string, pageSize = 20) => {
  const { id } = useAuth();
  const [retailers, setRetailers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);

  const loadInitial = async () => {
    if (!id) return;
    setLoading(true);
    if (retailers.length !== 0) {
      setRetailers([]);
      setLastDoc(null);
      setHasMore(true);
    }

    const retailersWhere = [where('roles', 'array-contains', 'retailer')];

    switch (selectedCategory) {
      case 'actionRequired': {
        retailersWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
        break;
      }
      case 'numberWrong': {
        retailersWhere.push(where('phoneCorrect', '==', false));
        break;
      }
      case 'noWhatsApp': {
        retailersWhere.push(where('hasWhatsApp', '==', false));
        break;
      }
      case 'enabledWhatsApp': {
        retailersWhere.push(where('enabledWhatsApp', '==', true));
        break;
      }
      default: {
        break;
      }
    }

    const retailersQuery = query(
      collection(firestore, `users/${id}/associates`),
      ...retailersWhere,
      orderBy('name'),
      startAfter(lastDoc),
      limit(pageSize)
    );

    const retailersSnap = await getDocs(retailersQuery);

    setLoading(false);
    if (retailersSnap.empty) {
      setHasMore(false);
      return;
    }
    const newRetailers = retailersSnap.docs.map(
      (ret) => docToJSON(ret) as User
    );
    if (newRetailers.length < pageSize) setHasMore(false);
    setRetailers(newRetailers);
    setLastDoc(retailersSnap.docs[newRetailers.length - 1]);
  };

  const loadMore = async () => {
    if (!id) return;
    if (!hasMore) return;
    setLoading(true);
    const retailersWhere = [where('roles', 'array-contains', 'retailer')];

    switch (selectedCategory) {
      case 'actionRequired': {
        retailersWhere.push(where('status', 'in', ['changes', 'unconfirmed']));
        break;
      }
      case 'numberWrong': {
        retailersWhere.push(where('phoneCorrect', '==', false));
        break;
      }
      case 'noWhatsApp': {
        retailersWhere.push(where('hasWhatsApp', '==', false));
        break;
      }
      case 'enabledWhatsApp': {
        retailersWhere.push(where('enabledWhatsApp', '==', true));
        break;
      }
      default: {
        break;
      }
    }

    const retailersQuery = query(
      collection(firestore, `users/${id}/associates`),
      ...retailersWhere,
      orderBy('name'),
      limit(pageSize)
    );

    const retailersSnap = await getDocs(retailersQuery);
    setLoading(false);
    if (retailersSnap.empty) {
      setHasMore(false);
      return;
    }
    const newRetailers = retailersSnap.docs.map(
      (ret) => docToJSON(ret) as User
    );
    if (newRetailers.length < pageSize) setHasMore(false);
    setRetailers(retailers.concat(newRetailers));
    setLastDoc(retailersSnap.docs[newRetailers.length - 1]);
  };

  return {
    retailers,
    loading,
    hasMore,
    loadInitial,
    loadMore,
  };
};

export const useRetailer = (id?: string) => {
  const checkAccess = useCheckAccess();
  const { error, loading, setError, setValue, value, setLoading } =
    useLoadingValue<DocumentSnapshot, FirebaseError>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (id) {
      if (!loading) setLoading(true);
      const ref = doc(firestore, 'users', id);
      unsubscribe = onSnapshot(ref, setValue, setError);
    }
    setValue(undefined);
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const create = async (data: CreateRetailerData, user: User) => {
    try {
      setLoading(true);
      await createRetailer(checkAccess(['sop', 'zsm']), data, user);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const update = async (changes: UserChanges) => {
    if (!id) return;
    try {
      setLoading(true);
      await registerRetailerChanges(id, checkAccess(['sop', 'zsm']), changes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setError(err);
    }
  };

  const confirmRegistration = async (confirmed: boolean, comment?: string) => {
    if (!id || !checkAccess(['sop', 'zsm'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmRetailerRegistration(id)
        : await rejectRetailerRegistration(id, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChanges = async (
    confirmed: boolean,
    changes: UserChanges,
    comment?: string
  ) => {
    if (!id || !checkAccess(['sop', 'zsm'])) return;
    try {
      setLoading(true);
      confirmed
        ? await confirmRetailerChanges(id, changes)
        : await rejectRetailerChanges(id, changes, comment);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmRejection = async () => {
    if (!id || !checkAccess(['fsa', 'npd_sa'])) return;
    try {
      setLoading(true);
      confirmRetailerRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const confirmChangesRejection = async () => {
    if (!id || !checkAccess(['fsa', 'npd_sa'])) return;
    try {
      setLoading(true);
      confirmRetailerChangesRejection(id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  return useMemo(
    () => ({
      retailer: value ? (docToJSON(value) as User) : null,
      error,
      loading,
      create,
      update,
      confirmChanges,
      confirmRegistration,
      confirmRejection,
      confirmChangesRejection,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, value]
  );
};
